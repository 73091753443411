export default [
    {
        title: "Keyword Manager",
        icon: "TypeIcon",
        resource: "Invoice",
        action: "manage",
        children: [
            {
                title: "K. Analyzer MAX",
                icon: "SearchIcon",
                route: "keyword-manager-analayzer-max",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "Keyword Analyzer",
                icon: "SearchIcon",
                route: "keyword-manager-keyword-search",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "Keyword Research",
                icon: "SearchIcon",
                route: "keyword-manager-keyword-research",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "Keyword Import",
                icon: "UploadIcon",
                route: "keyword-manager-keyword-import",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "Keyword Suggests",
                icon: "SearchIcon",
                route: "keyword-manager-suggestions",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "New Keyword Import",
                icon: "UploadIcon",
                route: "keyword-manager-new-keyword-import",
                resource: "Invoice",
                action: "manage",
            },
            {
                title: "New Keyword Research",
                icon: "SearchIcon",
                route: "keyword-manager-new-keyword-research",
                resource: "Invoice",
                action: "manage",
            }

        ],
    },
];
